import MPRestService from './MPRestService';

class AuthService extends MPRestService {
  endpoint;

  constructor() {
    super();
    this.endpoint = '/auth';
  }

  /**
   *
   * @param {*} email
   * @param {*} password
   * @returns {{isAuthorized, uid, email, password}} authData
   */
  async login(email, password) {
    const data = {
      email,
      password,
    };

    try {
      return await this._post(this.endpoint, data);
    } catch (e) {
      throw new Error('Login fehlgeschlagen. Bitte prüfen Sie ihre Angaben.');
    }
  }
}

export default AuthService;
