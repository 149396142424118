import { BACKEND_API_GATEWAY } from '../utils/constants/BACKEND_API_GATEWAY';
import MPRestService from './MPRestService';

class ConsultingSessionService extends MPRestService {
  endpoint;
  credentials;
  constructor(credentials = {}) {
    super();
    this.endpoint = '/consulting-sessions';
    this.credentials = credentials;
  }

  async newConsultingSession(consultingSession) {
    return await this._post(this.endpoint, consultingSession);
  }

  async getAllConsultingSessions() {
    return await this._get(this.endpoint);
  }

  async updateConsultingSession(consultingSession) {
    return await this._patch(this.endpoint, consultingSession);
  }

  async deleteConsultingSession(id) {
    const endpoint = `${this.endpoint}/${id}`;
    return await this._patch(endpoint);
  }
  // Stunden erfassen / Auswahlfelder befüllen
  async getAllPlaces() {
    return await this._get('/places');
  }
  async getAllActivities() {
    return await this._get('/activities');
  }
  async getAllCultures() {
    return await this._get('/cultures');
  }

  static getAPIURL() {
    return `${BACKEND_API_GATEWAY}/consulting-sessions`;
  }
}

export default new ConsultingSessionService();
