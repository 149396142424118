import AgricultureIcon from '@mui/icons-material/Agriculture';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ForestIcon from '@mui/icons-material/Forest';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { useGlobalContext } from '../../contexts/globalContext';
import H from '../../helper/helper';

const styles = StyleSheet.create({
  item: {
    backgroundColor: 'transparent',
    marginHorizontal: 10,
  },
  textHeaderFirstChild: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  textBody: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'normal',
    marginHorizontal: 15,
  },
  firstRow: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    lineHeight: 20,
  },
  row: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    lineHeight: 20,
    marginLeft: 8,
    paddingBottom: 5,
  },
  rowLast: {
    backgroundColor: 'transparent',
    width: '100%',
  },

  subheadline: {
    fontSize: 14,
  },
  moreIconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },

  rowContainer: {
    backgroundColor: '#D5F5E3',
    flexDirection: 'row',
    marginHorizontal: 10,
  },
});

const ConsultingSessionListItem = ({
  item,
  member,
  memberPersonalDataLoading,
  activity,
  culture,
  comment,
  consultantMemberLoginID,
  cMEMail,
  handleMoreOptionsMenu,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const { auth } = useGlobalContext();

  const onLongPress = (event) => {
    const { clientX, clientY } = event?.targetTouches?.[0];
    handleMoreOptionsMenu({ clientX, clientY }, item);
  };

  const onClick = (event) => {
    setIsExpanded(!isExpanded);
  }; 

  const toggleExpand = (event) => {
    event.stopPropagation(); // prevent propagation up to onLongPress, which would stop the toggleExpand logic
    setIsExpanded(!isExpanded);
  };

  const colors = {
    blue: ['#96cdea', '#91d7e9'],
    red: ['#f9dddd', '#f2c9c9'],
  };

  const setBackgroundColor = () => {
    return consultantMemberLoginID?.toString() === auth.uid.toString() ? colors.blue : colors.red;
  };

  const isExternal = consultantMemberLoginID?.toString() !== auth.uid.toString();

  const iconColor = {
    blue: '#1e6495',
    red: '#e79696',
  };

  const setIconColor = () => {
    return consultantMemberLoginID?.toString() === auth.uid.toString()
      ? iconColor.blue
      : iconColor.red;
  };
  const setBoxShadow = () => {
    return consultantMemberLoginID?.toString() === auth.uid.toString()
      ? '5px 5px 15px rgb(30 100 149 /40%)'
      : '5px 5px 15px rgb(231 150 150/40%)';
  };

  let memberDisplayName = '';
  if(member?.firstname || member?.lastname) {
    memberDisplayName = `${member.firstname}`
    memberDisplayName += member?.lastname ? ' ' + member?.lastname : '';
  } else {
    // company name
    if(member?.addition3) {
      memberDisplayName = `${member.addition3}`
      memberDisplayName += member?.addition2 ? ' ' + member?.addition2 : '';
      memberDisplayName += member?.addition1 ? ' ' + member?.addition1 : '';

    } else if(member?.addition2) {
      memberDisplayName = `${member.addition2}`
      memberDisplayName += member?.addition1 ? ' ' + member?.addition1 : '';
    } else if(member?.addition1) {
      memberDisplayName = `${member.addition1}`
    }
  }

  let memberDisplayNumber = item.memberID ?? '';

  return (
    <Box
      sx={{
        marginTop: 2,
        borderRadius: 2,
        background: `linear-gradient(to right, ${setBackgroundColor()})`,
        marginX: 2,
        boxShadow: `${setBoxShadow()}`,
        padding: '10px',
      }}>
      <View style={styles.item}>
        <View style={styles.firstRow}>
          {memberPersonalDataLoading ? (
            <>
              <Skeleton variant="text" sx={{ width: 200 }} />
            </>
          ) : (
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
                paddingBottom: isExternal ? 0 : 7,
              }}>{`${memberDisplayName} [${memberDisplayNumber}]`}</Text>
          )}
          {memberPersonalDataLoading ? (
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          ) : (
            isExternal && (
              <Text style={styles.subheadline}>{`Stunden erfasst durch: ${cMEMail}`}</Text>
            )
          )}
        </View>
        <View style={styles.moreIconContainer}>
          {!isExternal && <MoreVertIcon onClick={(event) => handleMoreOptionsMenu(event, item)} />}
        </View>
        <View style={styles.row}>
          {memberPersonalDataLoading ? (
            <Skeleton variant="circular" sx={{ width: 25, height: 25 }} />
          ) : (
            <AgricultureIcon sx={{ color: setIconColor() }} />
          )}

          <Text style={styles.textHeaderFirstChild} />
          <Text style={styles.textBody}>
            {memberPersonalDataLoading ? (
              <Skeleton variant="text" sx={{ width: 100 }} />
            ) : (
              activity?.name ?? '--'
            )}
          </Text>
        </View>
        <View style={styles.row}>
          {memberPersonalDataLoading ? (
            <Skeleton variant="circular" sx={{ width: 25, height: 25 }} />
          ) : (
            <EventNoteIcon sx={{ color: setIconColor() }} />
          )}
          <Text style={styles.textHeaderFirstChild} />
          <Text style={styles.textBody}>
            {memberPersonalDataLoading ? (
              <Skeleton variant="text" sx={{ width: 200 }} />
            ) : (
              `${H.formatDateString(item?.startDate)} - ${
                item.hours && item.hours != 0 ? `${item.hours}h ` : ''
              }${item.minutes && item.minutes != 0 ? `${item.minutes}min` : ''}`
            )}
          </Text>
        </View>

        <View style={styles.row}>
          {memberPersonalDataLoading ? (
            <Skeleton variant="circular" sx={{ width: 25, height: 25 }} />
          ) : (
            <ForestIcon sx={{ color: setIconColor() }} />
          )}

          <Text style={styles.textHeaderFirstChild} />
          <Text style={styles.textBody}>
            {memberPersonalDataLoading ? (
              <Skeleton variant="text" sx={{ width: 100 }} />
            ) : (
              culture?.name ?? '--'
            )}
          </Text>
        </View>

        <View style={styles.rowLast}>
          {!memberPersonalDataLoading && comment?.trim() !== '' && (
            <>
              <TouchableOpacity onPress={toggleExpand}>
                <Text>
                  {isExpanded ? (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: '400',
                        margin: 8,
                        padding: 10,
                        minHeight: 50,
                        color: '#000000',
                        borderRadius: 5,
                        backgroundColor: '#ffffff',
                        border: `1px solid ${setIconColor()}`,
                      }}>
                      {comment}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: '600',
                        margin: 8,
                        padding: 7,
                        color: '#ffffff',
                        borderRadius: 5,
                        backgroundColor: `${setIconColor()}`,
                      }}>
                      Kommentar anzeigen
                    </div>
                  )}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>
    </Box>
  );
};
export default ConsultingSessionListItem;
