import React from 'react'; // Don't forget to import React
import { Image } from 'react-native';

import Beratungsstunden from '../../../assets/icons/beratungsstunden.svg'; // Change the import path accordingly

const BeratungsstundenIcon = ({ width, height }) => {
  const _w = width || 30;
  const _h = height || 30;

  const filterStyle = {
    filter:
      'brightness(0) saturate(100%) invert(35%) sepia(1%) saturate(0%) hue-rotate(315deg) brightness(101%) contrast(94%)',
  };

  return <Image style={{ width: _w, height: _h, ...filterStyle }} source={Beratungsstunden} />;
};

export default BeratungsstundenIcon;
