import { create } from 'zustand';

export const useGlobalContext = create((set) => ({
  auth: {
    uid: '',
    email: '',
    password: '',
    timestamp: '',
  },
  tabs: {
    activeTab: 0,
  },

  memberName: null,
  historyMemberID: null,
  passwordTmp: false,
  showMemberContactInfo: false,

  setPasswordTmp: (state) => set({ passwordTmp: state }),
  setMemberName: (newName) => set({ memberName: newName }),
  setSelectedRow: (row) => set({ selectedRow: row }),
  setCredentials: (credentials) => set((state) => ({ auth: credentials })),
  setActiveTab: (tab) => set({ activeTab: tab }),
  setHistoryMemberID: (newName) => set({ historyMemberID: newName }),
  setShowMemberContactInfo: (state) => set({ showMemberContactInfo: state }),
}));
