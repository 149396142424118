import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {BottomNavigation, BottomNavigationAction} from '@mui/material';

import ActiveBeratungsstundenIcon from '../../components/Common/ActiveBeratungsstundenIcon';
import BeratungsstundenIcon from '../../components/Common/BeratungsstundenIcon';
import {useGlobalContext} from '../../contexts/globalContext';
import SCREEN from '../../utils/constants/SCREEN';

const MPBottomNavigationBar = ({state, descriptors, navigation}) => {
  const {activeTab, setActiveTab, setMemberName, setShowMemberContactInfo} = useGlobalContext();
  const hoursTabActive = activeTab === 1;

  return (
      <BottomNavigation
          showLabels
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
            setMemberName(null);
          }}

          style={{backgroundColor: '#e5e5e5', height: 70}}
          sx={{
            '.Mui-selected': {
          color: '#1e6495',
            },
            '.Mui-selected .MuiBottomNavigationAction-label': {
              color: '#1e6495',
            },
            '.Mui-selected .MuiSvgIcon-root': {
              color: '#1e6495',
            },
          }}>
        {state.routes.map((route, index) => {
          const {options} = descriptors[route.key];

          let iconComponent = null;
          switch (route.name) {
            case SCREEN.HOME:
              iconComponent = <WidgetsIcon/>;
              break;
            case SCREEN.HOURS:
              iconComponent = hoursTabActive ? (
                  <ActiveBeratungsstundenIcon width={30} height={30}/>
              ) : (
                  <BeratungsstundenIcon width={30} height={30}/>
              );
              break;
            case SCREEN.PROFILE:
              iconComponent = <PersonOutlineOutlinedIcon/>;
              break;

            default:
              break;
          }

          const label =
              options.tabBarLabel !== undefined
                  ? options.tabBarLabel
                  : options.title !== undefined
                      ? options.title
                      : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate({name: route.name, merge: true});
            }
            setShowMemberContactInfo(false);
          };

          return (
              <BottomNavigationAction
                  key={route.key}
                  label={label}
                  onClick={onPress}
                  icon={iconComponent}
              />
          );
        })}
      </BottomNavigation>
  );
};

export default MPBottomNavigationBar;
