import { Image } from 'react-native';

import Icon from '../../../assets/images/mpunkt_logo.svg';

/**
 * Generate logo component with specified width and height.
 *
 * @param {{width: number, height: number}} props - The height and width of the logo.
 * @return {JSX.Element} The logo component.
 */
const CompanyLogo = ({ width, height }) => {
  const _w = width;
  const _h = height;

  return <Image style={{ width: _w, height: _h, resizeMode: 'contain' }} source={Icon} />;
};

export default CompanyLogo;
