import { DataGrid, deDE } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import FadeInHeaderImage from '../components/Common/FadeInHeaderImage';
import ConsultingSessionService from '../services/ConsultingSessionService';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    position: 'absolute',
    top: 80, // Adjust this value based on your header's height
    left: 0,
    right: 0,
    bottom: 0,
  },
  noDataContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 10,
  },
  noData: {
    fontSize: 20,
  },
});

export default function ConsultingPlaces({ navigation, route }) {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await ConsultingSessionService.getAllPlaces();
        setData(response || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'uid',
        headerName: 'ID',
        width: 70,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
    ],
    [],
  );

  useEffect(() => {
    const mappedRows = data.map((item, index) => ({
      id: index,
      uid: item.uid,
      name: item.name,
    }));
    setRows(mappedRows);
  }, [data]);

  return (
    <View style={styles.container}>
      <View>
        <FadeInHeaderImage heading="Veranstaltungsorte" navigation={navigation} />
      </View>
      <View style={styles.contentContainer}>
        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          components={{
            NoRowsOverlay: () => (
              <View style={styles.noDataContainer}>
                <Text style={styles.noData}>Es wurden keine Daten gefunden.</Text>
              </View>
            ),
          }}
          sx={{
            '& .MuiDataGrid-footerContainer': {
              justifyContent: 'center',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: ' none'
            },
          }}
        />
      </View>
    </View>
  );
}
