// @ts-nocheck
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {Box, Button, MenuItem, Select, TextField, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {deDE} from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import React, {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import {useGlobalContext} from '../../contexts/globalContext';
import H from '../../helper/helper';
import {useResource} from '../../hooks/useResource';
import ConsultingSessionService from '../../services/ConsultingSessionService';
import SCREEN from '../../utils/constants/SCREEN';

const genericButtonStyle = {
  width: 350,
  height: 50,
  alignSelf: 'center',
  color: '#FFFFFF',
  marginBottom: 15,
};

const styles = StyleSheet.create({
  savebtn: {
    ...genericButtonStyle,
    backgroundColor: '#1e6495',
  },
  editbtn: {
    ...genericButtonStyle,
    backgroundColor: '#d77c46',
  },
  cancelbtn: {
    ...genericButtonStyle,
    backgroundColor: '#84868a',
  },
  backButton: {
    marginTop: 16,
    fontSize: 20,
    color: '#1e6495',
    paddingLeft: 10,
  },
  uploadPhoto: {
    position: 'absolute',
    top: '8px',
    right: '20px',
  },
});

const ConsultingSessionEditForm = ({navigation, route}) => {
  const {row, activities, cultures, places} = route.params;
  const {auth, setActiveTab, setMemberName, setHistoryMemberID} = useGlobalContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showErrorTime, setShowErrorTime] = useState(false);
  const [showErrorMember, setShowErrorMember] = useState(false);
  const [countErrors, setCountErrors] = useState(0);

  const [formState, setFormState] = useState({
    user_uid: auth.uid,
    member_uid: row.memberID,
    activity_uid: row.activityID,
    place_uid: row.locationID,
    culture_uid: row.cultureID,
    comment: row.comment,
    startDate: row.startDate,
    hours: row.hours,
    minutes: row.minutes,
  });

  const helper = new H();

  const {data: memberPersonalDataAutoComplete, loading: memberPersonalDataAutoCompleteLoading} =
      useResource({
        name: 'memberPersonalDataAutoComplete',
        defaultData: [],
      });

  const handleChangeDate = (newValue) => {
    const month = newValue['$M'] + 1;
    const newStartDate = `${newValue['$y']}-${month}-${newValue['$D']}`;
    setFormState({...formState, startDate: newStartDate});
  };

  const handleSaveHours = async () => {
    const formattedStartDate = helper.formatDateToYYYYMMDD(new Date(formState.startDate));

    let updatedCountErrors = 0;

    if (!formState.member_uid) {
      setShowErrorMember(true);
      updatedCountErrors += 1;
    } else {
      setShowErrorMember(false);
    }

    if (!formState.hours && !formState.minutes) {
      setShowErrorTime(true);
      updatedCountErrors += 1;
    } else {
      setShowErrorTime(false);
    }

    if (formState.culture_uid.length === 0) {
      setShowError(true);
      updatedCountErrors += 1;
    } else {
      setShowError(false);
    }

    if (updatedCountErrors === 0) {
      const consultingSession = {
        uid: row.uid,
        memberID: formState.member_uid,
        activityID: formState.activity_uid,
        locationID: formState.place_uid,
        cultureID: formState.culture_uid,
        comment: formState.comment,
        startDate: formattedStartDate,
        hours: formState.hours,
        minutes: formState.minutes,
      };

      await ConsultingSessionService.updateConsultingSession(consultingSession);
      setCountErrors(0);
      setMemberName(null);
      setHistoryMemberID(null);
      navigation.navigate(SCREEN.HOURS, {});
    }
  };

  const handlePress = () => {
    navigation.goBack();
    setMemberName(null);
    setHistoryMemberID(null);
  };

  const MAX_OPTIONS = 100; // Maximale Anzahl der angezeigten Optionen

  // Filterfunktion, die auf 100 Elemente begrenzt
  const filterOptions = (options, {inputValue}) => {
    const inputValueLower = inputValue.toLowerCase();
    const filteredOptions = options.filter((option) => {
      const nameMatch = option.name && option.name.toLowerCase().includes(inputValueLower);
      const streetMatch = option.street && option.street.toLowerCase().includes(inputValueLower);
      const cityMatch = option.city && option.city.toLowerCase().includes(inputValueLower);
      const zipMatch = option.zip && option.zip.toLowerCase().includes(inputValueLower);
      const IDMatch = option.memberID && option.memberID.toLowerCase().includes(inputValueLower);
      return nameMatch || streetMatch || cityMatch || zipMatch || IDMatch;
    });
    return filteredOptions.slice(0, MAX_OPTIONS);
  };

  return (
      !memberPersonalDataAutoCompleteLoading && (
          <View>
            <TouchableOpacity onPress={handlePress}>
              <Text style={styles.backButton}>
                <ArrowBackIosNewOutlinedIcon style={{verticalAlign: 'bottom'}}/>
                zurück
              </Text>
            </TouchableOpacity>
            <Box sx={{display: 'flex', justifyContent: 'center', paddingTop: 2, paddingBottom: 2}}>
              <Typography variant="h5">Datensatz ändern</Typography>
            </Box>
            <View style={{flex: 1}}>
              <Box sx={{mx: 2, mb: 2}}>
                <FormControl fullWidth>
                  <Autocomplete
                      autoHighlight
                      blurOnSelect
                      noOptionsText="Keine Ergebnisse gefunden"
                      filterOptions={filterOptions}
                      options={memberPersonalDataAutoComplete}
                      getOptionLabel={(value) => {
                        const memberData = memberPersonalDataAutoComplete.find(
                            (item) => item?.memberID?.toString() === value.toString(),
                        );
                        return memberData?.name || `${memberData?.firstname} ${memberData?.lastname}`;
                      }}
                      renderOption={(props, option, index) => {
                        const optionStyle = {
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'left',
                        };

                        const nameStyle = {
                          fontSize: '16px',
                        };

                        const addressStyle = {
                          fontSize: '12px',
                        };

                        return (
                            <li {...props} key={option.memberID}>
                              <div style={optionStyle}>
                        <span style={nameStyle}>
                          {option?.name} <strong>[{option?.memberID}]</strong>
                        </span>
                                <span style={addressStyle}>
                          {option?.street}
                                  {option?.zip ? `, ${option?.zip}` : ''}
                                  {option?.city ? ` ${option?.city}` : ''}
                        </span>
                              </div>
                            </li>
                        );
                      }}
                      value={formState.member_uid}
                      isOptionEqualToValue={(option, value) => {
                        return option?.memberID?.toString() === value?.toString();
                      }}
                      renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label="Mitglied"
                                error={showErrorMember}
                                helperText={
                                  showErrorMember ? 'Bitte wählen Sie mindestens ein Mitglied aus.' : ''
                                }
                            />
                        );
                      }}
                      onChange={(event, option) => {
                        setFormState((prevState) => ({...prevState, member_uid: option?.memberID}));
                      }}
                  />
                </FormControl>
              </Box>
            </View>
            <View>
              <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                  localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
                <MobileDatePicker
                    disableFuture
                    label="Datum"
                    format="DD.MM.YYYY"
                    value={dayjs(formState.startDate)}
                    onChange={handleChangeDate}
                    closeOnSelect
                    slotProps={{
                      textField: {
                        InputProps: {
                          endAdornment: (
                              <InputAdornment position="end">
                                <CalendarMonthOutlinedIcon/>
                              </InputAdornment>
                          ),
                        },
                      },
                    }}
                    sx={{mx: 2, mb: 2}}
                />
              </LocalizationProvider>
            </View>
            <View style={{flexDirection: 'row'}}>
              <View style={{flex: 1}}>
                <Box sx={{ml: 2, mr: 1, mb: 2}}>
                  <FormControl fullWidth error={showErrorTime}>
                    <InputLabel id="hours-select-label">Stunden</InputLabel>
                    <Select
                        labelId="hours-select-label"
                        id="hours-select"
                        value={formState.hours}
                        label="Stunden"
                        onChange={(event) => {
                          setFormState((prevState) => ({...prevState, hours: event.target.value}));
                        }}>
                      {[...Array(25)].map((_, index) => (
                          <MenuItem key={index} value={index}>
                            {index}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </View>
              <View style={{flex: 1}}>
                <Box sx={{mr: 2, ml: 1, mb: 2}}>
                  <FormControl fullWidth error={showErrorTime}>
                    <InputLabel id="minutes-select-label">Minuten</InputLabel>
                    <Select
                        labelId="minutes-select-label"
                        id="minutes-select"
                        value={formState.minutes}
                        label="Minuten"
                        onChange={(event) => {
                          setFormState((prevState) => ({...prevState, minutes: event.target.value}));
                        }}>
                      {[0, 15, 30, 45].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </View>
            </View>
            <View style={{flex: 1}}>
              <Box sx={{mx: 2, mb: 2}}>
                <FormControl fullWidth>
                  <Autocomplete
                      autoHighlight
                      blurOnSelect
                      noOptionsText="Keine Ergebnisse gefunden"
                      options={activities}
                      value={
                          activities.find((activity) => activity.uid.toString() ===  formState.activity_uid.toString()) || null
                      }
                      getOptionLabel={(activity) => activity.name}
                      renderInput={(params) => <TextField {...params} label="Tätigkeit"/>}
                      onChange={(event, value) => {
                        if (value) {
                          setFormState((prevState) => ({
                            ...prevState,
                            activity_uid: value.uid,
                          }));
                        } else {
                          setFormState((prevState) => ({
                            ...prevState,
                            activity_uid: '',
                          }));
                        }
                      }}
                  />
                </FormControl>
              </Box>
            </View>
            <View style={{flex: 1}}>
              <Box sx={{mx: 2, mb: 2}}>
                <FormControl fullWidth>
                  <Autocomplete
                      autoHighlight
                      blurOnSelect
                      noOptionsText="Keine Ergebnisse gefunden"
                      options={places}
                      value={places.find((place) =>  place.uid.toString() ===  formState.place_uid.toString()) || null}
                      getOptionLabel={(places) => places.name}
                      renderInput={(params) => <TextField {...params} label="Ort"/>}
                      onChange={(event, value) => {
                        if (value) {
                          setFormState((prevState) => ({
                            ...prevState,
                            place_uid: value.uid,
                          }));
                        } else {
                          setFormState((prevState) => ({
                            ...prevState,
                            place_uid: '',
                          }));
                        }
                      }}
                  />
                </FormControl>
              </Box>
            </View>
            <View style={{flex: 1}}>
              <Box sx={{mx: 2, mb: 2}}>
                <FormControl fullWidth>
                  <Autocomplete
                      autoHighlight
                      blurOnSelect
                      noOptionsText="Keine Ergebnisse gefunden"
                      options={cultures}
                      value={cultures.find((culture) =>   culture.uid.toString() ===  formState.culture_uid.toString()) || null}
                      getOptionLabel={(cultures) => cultures.name}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Kultur"
                              error={showError}
                              helperText={showError ? 'Kultur darf nicht leer sein.' : ''}
                          />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          setFormState((prevState) => ({
                            ...prevState,
                            culture_uid: value.uid,
                          }));
                        } else {
                          setFormState((prevState) => ({
                            ...prevState,
                            culture_uid: '',
                          }));
                        }
                      }}
                  />
                </FormControl>
              </Box>
            </View>
            <TextField
                label="Bemerkung"
                placeholder="Bemerkung"
                value={formState.comment}
                onChange={(event) => {
                  setFormState((prevState) => ({...prevState, comment: event.target.value}));
                }}
                multiline
                rows={3}
                sx={{mx: 2, mb: 2}}
            />
            <>
              <Button style={styles.savebtn} onClick={handleSaveHours}>
                Änderungen speichern
              </Button>
              <Button style={styles.cancelbtn} onClick={handlePress}>
                Abbrechen
              </Button>
            </>
          </View>
      )
  );
};

export default React.memo(ConsultingSessionEditForm);
