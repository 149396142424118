import axios from 'axios';
import { useState } from 'react';

import { BACKEND_API_GATEWAY } from '../utils/constants/BACKEND_API_GATEWAY';

const useDeleteResource = ({ name, uid = 0, method = 'DELETE' }) => {
  /**
   * The state that is returned by the hook
   * this provide an "API" to work with the useResource hook
   */
  const [hookState, setHookState] = useState({
    method,
    error: null,
    loading: true,
    url: `/${name}`,
    resourceName: name,
    baseURL: BACKEND_API_GATEWAY,
    delete: (uid) => doJob(uid), // reloads the resource using current axiosConfig in state, exposed to the calling Component
  });

  /**
   * Remember the axiosConfig in state, used when reload is called
   */
  const [axiosConfig, setAxiosConfig] = useState({
    baseURL: BACKEND_API_GATEWAY,
    method,
    url: `/${name}`,
    headers: {},
    data: {
      uid,
    },
  });

  /**
   * This function is used to do the actual job
   *
   * @returns
   */
  const doJob = async (uid) => {
    setHookState((previousState) => {
      return {
        ...previousState,
        loading: true,
      };
    });

    try {
      const newAxiosConfig = { ...axiosConfig };
      if (uid) {
        newAxiosConfig.data = { ...newAxiosConfig.data, uid };
        setAxiosConfig(newAxiosConfig);
      }

      const response = await axios(newAxiosConfig);

      // Expose the data & loading state to caller
      setHookState((previousState) => {
        return {
          ...previousState,
          loading: false,
          data: response?.data,
          response,
        };
      });
    } catch (error) {
      const response = error?.response;
      // Expose the error & loading state to caller
      setHookState((previousState) => {
        return {
          ...previousState,
          loading: false,
          error,
          response,
        };
      });
    }
  };

  return hookState;
};

export { useDeleteResource };

