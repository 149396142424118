import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaView } from 'react-native';

import CopyHoursForm from './src/components/ConsultingSessionList/ConsultingSessionCopyForm';
import EditHoursForm from './src/components/ConsultingSessionList/ConsultingSessionEditForm';
import MPAppBar from './src/components/Layout/MPAppBar';
import { AppContextProvider } from './src/contexts/AppContext';
import { useNetworkStatus } from './src/hooks/useNetworkStatus';
import ConsultingSessionCreate from './src/screens/ConsultingSessionCreate';
import ConsultingActivities from './src/screens/ConsultingActivities';
import ConsultingPlaces from './src/screens/ConsultingPlaces';
import InnerApp from './src/screens/InnerApp';
import Login from './src/screens/Login';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import SCREEN from './src/utils/constants/SCREEN';
import destroyIOSStupidScroll from './src/utils/destroyIOSStupidScroll';

const Stack = createNativeStackNavigator();

export default function App() {
  /** STATIC DEFINITIONEN */
  const screenOptions = {
    title: '',
    headerBackImageSource: null,
    headerShown: false,
  };

  /** STATES */
  const isConnected = useNetworkStatus();

  return (
      <AppContextProvider>
        {/* children component can read AppContext */}
        <NavigationContainer>
          {/* children component can use react-navigation */}
          <SafeAreaView style={{ flex: 1, backgroundColor: '#E5E5E5FF' }}>
            <Stack.Navigator initialRouteName={SCREEN.LOGIN}>
              {/* children components are screen components, stacked navigation */}
              <Stack.Screen name={SCREEN.LOGIN} component={Login} options={screenOptions} />
              <Stack.Screen name={SCREEN.INNER_APP} component={InnerApp} options={screenOptions} />
              <Stack.Group screenOptions={{ presentation: 'modal' }}>
                <Stack.Screen
                    name={SCREEN.ADD_HOURS}
                    component={ConsultingSessionCreate}
                    options={({ route }) => ({
                      header: (props) => <MPAppBar {...props} />,
                      title: 'Beratungsstunden hinzufügen',
                    })}
                />
                <Stack.Screen
                    name={SCREEN.CONSULTING_ACTIVITIES}
                    component={ConsultingActivities}
                    options={({ route }) => ({
                      header: (props) => <MPAppBar {...props} />,
                      title: 'Beratungstätigkeiten',
                    })}
                />
                <Stack.Screen
                    name={SCREEN.CONSULTING_PLACES}
                    component={ConsultingPlaces}
                    options={({ route }) => ({
                      header: (props) => <MPAppBar {...props} />,
                      title: 'Veranstaltungsorte',
                    })}
                />
                <Stack.Screen
                    name={SCREEN.EDIT_CONSULTING_SESSION}
                    component={EditHoursForm}
                    options={({ route }) => ({
                      header: (props) => <MPAppBar {...props} />,
                      title: 'Beratungsstunden ändern',
                    })}
                />
                <Stack.Screen
                    name={SCREEN.COPY_CONSULTING_SESSION}
                    component={CopyHoursForm}
                    options={({ route }) => ({
                      header: (props) => <MPAppBar {...props} />,
                      title: 'Beratungsstunden duplizieren',
                    })}
                />
              </Stack.Group>
            </Stack.Navigator>
          </SafeAreaView>
        </NavigationContainer>
      </AppContextProvider>
  );
}

/**
 * We execute some initialization code before the app is ready
 */
((window) => {
  // iOS support
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  if (isIOS) {
    destroyIOSStupidScroll(window);
  }
})(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
