import { Image } from 'react-native';

import Icon from '../../../assets/images/login-bg-img.jpg';

/**
 *
 * @param {{width?: any, height?: any}} param0
 * @returns
 */
const HeaderImage = ({ width = null, height = null }) => {
  const _w = width || '100%';
  const _h = height || 208;

  return <Image style={{ width: _w, height: _h }} source={Icon} />;
};

export default HeaderImage;
