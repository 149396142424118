import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@mui/material';

function DeleteModal({
  setMoreOptionsOpen,
  setDeleteDialogOpen,
  deleteDialogOpen,
  handleConfirmDelete,
  setMemberName,
}) {
  return (
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Beratungsstunde löschen?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Soll die Beratungsstunde gelöscht werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleConfirmDelete}>
          ja
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDeleteDialogOpen(false);
            setMoreOptionsOpen(true);
          }}>
          Nein
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteModal;
