import { Image } from 'react-native';

import Beratungsstunden from '../../../assets/icons/beratungsstunden_active.svg'; // Change the import path accordingly

const ActiveBeratungsstundenIcon = ({ width, height }) => {
  const _w = width || 30;
  const _h = height || 30;

  const filterStyle = {
    filter:
      'brightness(0) saturate(100%) invert(28%) sepia(82%) saturate(446%) hue-rotate(160deg) brightness(104%) contrast(97%)',
  };

  return <Image style={{ width: _w, height: _h, ...filterStyle }} source={Beratungsstunden} />;
};

export default ActiveBeratungsstundenIcon;
