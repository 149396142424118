import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';

export const OverlapSessionIndicator = ({ activities, cultures, places, members, consultingSessions }) => {

  const [showOverlapDialog, setShowOverlapDialog] = useState(false);
  const hasMultipleCandidates = consultingSessions.length > 1;
  const alertText = hasMultipleCandidates ? 'Beim Mitglied existieren bereits mehrere Eintragungen an diesem Tag' : 'Beim Mitglied existiert bereits eine Eintragung an diesem Tag';

  return (
    <>
      <Alert style={{ marginBottom: 10 }} severity="info">
        {alertText}
        <Button onClick={() => setShowOverlapDialog(true)}>...mehr lesen</Button>
      </Alert>

      <Dialog fullWidth open={showOverlapDialog} onClose={() => setShowOverlapDialog(false)} aria-labelledby="overlap-session-title" aria-describedby="overlap-session-description">
        <DialogTitle id="overlap-session-dialog-title">
          <Typography variant='h4'>
            Achtung!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="overlap-session-description">
            <Typography variant='body1'>
              Möglicherweise überlappende Beratungsstunden sind:
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <ul>
              {
                consultingSessions.map(cs => {
                  const memberName = members.find((m) => m.memberID === cs.memberID.toString())?.name;
                  const advisorName = members.find((m) => m.memberID === cs.consultantMemberLoginID.toString())?.name;
                  const activityName = activities.find((a) => a.uid === cs.activityID)?.name;
                  const placeName = places.find((p) => p.uid === cs.locationID)?.name;

                  return (
                    <li>
                      {`Mitglied ${memberName} am ${placeName}`}
                      <br />
                      {`mit Tätigkeit "${activityName}" `}
                      <br />
                      {`(durch ${advisorName} protokolliert)`}
                    </li>
                  );
                })
              }
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOverlapDialog(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
