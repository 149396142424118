import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {Text} from 'react-native';
import filterMemberOptions from '../../utils/filterMemberOptions';
import {useGlobalContext} from '../../contexts/globalContext';

const SideFilterMenu = ({
                          anchor,
                          open,
                          onOpen,
                          onClose,
                          activities = [],
                          cultures = [],
                          places = [],
                          memberPersonalDataAutoComplete = [],
                          memberPersonalDataAutoCompleteLoading = true,
                        }) => {
  const defaultFilter = {
    member_uid: 0,
    activity_uid: 0,
    place_uid: 0,
    culture_uid: 0,
    startDate: new Date(),
    endDate: new Date(),
  };
  const {setMemberName, memberName} = useGlobalContext();
  const [filterField, setFilterField] = useState(null);
  const [sortValue, setSortValue] = useState('standard');
  const [formState, setFormState] = useState(defaultFilter);

  const handleSortValueChanged = (event) => {
    setSortValue(event.target.value);
  };

  useEffect(() => {
    if (!memberName) {
      handleReset();
    }
  }, [memberName]);

  const handleReset = (event) => {
    setFormState(defaultFilter);
    setMemberName(null);
    onClose('', {}, '', true);
  };

  return (
      <SwipeableDrawer
          anchor={anchor}
          open={open}
          onClose={() => onClose(filterField, formState, sortValue)}
          onOpen={onOpen}
          PaperProps={{
            sx: {width: '100vw', borderTopLeftRadius: 25, borderBottomLeftRadius: 25},
          }}>
        <div
            className="container"
            style={{
              display: 'flex',
              paddingTop: '20px',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
          <div
              className="header"
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'center',
                marginTop: '20px',
                flex: 1,
              }}>
            <Typography
                sx={{display: 'flex', height: 'fit-content'}}
                variant="h5"
                fontWeight="bold"
                component="div">
              Einstellungen
            </Typography>
            <IconButton
                sx={{display: 'flex', height: 'fit-content', alignSelf: 'start'}}
                onClick={() => onClose('close', formState, sortValue)}>
              <CloseIcon fontSize="large"/>
            </IconButton>
          </div>

          <div
              className="body"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                justifyItems: 'space-between',
                flexDirection: 'column',
                flex: 1,
                padding: 20,
              }}>
            <Divider>
              <Text>Sortierung</Text>
            </Divider>
            <div style={{height: '20px'}}/>

            <FormControl>
              <RadioGroup value={sortValue} defaultValue="standard" onChange={handleSortValueChanged}>
                <FormControlLabel value="standard" control={<Radio/>} label="Standard"/>
                <FormControlLabel value="date_desc" control={<Radio/>} label="älteste zuerst"/>
                <FormControlLabel value="date_asc" control={<Radio/>} label="neueste zuerst"/>
                <FormControlLabel
                    value="lastname_asc"
                    control={<Radio/>}
                    label="Nachname aufsteigend"
                />
                <FormControlLabel
                    value="lastname_desc"
                    control={<Radio/>}
                    label="Nachname absteigend"
                />
              </RadioGroup>
            </FormControl>

            <div style={{height: '40px'}}/>
            <Divider>
              <Text>Filter</Text>
            </Divider>

            <div style={{height: '20px'}}/>

            <FormControl fullWidth>
              <Autocomplete
                  autoHighlight
                  blurOnSelect
                  noOptionsText="Keine Ergebnisse gefunden"
                  filterOptions={filterMemberOptions}
                  options={memberPersonalDataAutoComplete}
                  value={!memberPersonalDataAutoCompleteLoading && memberPersonalDataAutoComplete?.find(
                      (item) => item.memberID === formState?.member_uid,
                  )}
                  getOptionLabel={(value) => {
                    if (memberPersonalDataAutoCompleteLoading) {
                      return value;
                    }

                    const memberData = memberPersonalDataAutoComplete.find((item) => {
                      let idToFind = !isNaN(value) ? parseInt(value, 10) : null;
                      if (!idToFind) {
                        idToFind = value?.memberID.toString();
                      }
                      return item?.memberID.toString() === idToFind.toString();
                    });

                    if (memberData) {
                      const {name, street, city, zip, memberID} = memberData;
                      return `${name}`;
                    } else {
                      return 'Fehler';
                    }
                  }}
                  renderOption={(props, option, index) => {
                    const optionStyle = {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                    };

                    const nameStyle = {
                      fontSize: '16px',
                    };

                    const addressStyle = {
                      fontSize: '12px',
                    };

                    return (
                        <li {...props} key={option.memberID}>
                          <div
                              // @ts-ignore
                              style={optionStyle}>
                      <span style={nameStyle}>
                        {option?.name} <strong>[{option?.memberID}]</strong>
                      </span>
                            <span style={addressStyle}>
                        {option?.street}
                              {option?.zip ? `, ${option?.zip}` : ''}
                              {option?.city ? ` ${option?.city}` : ''}
                      </span>
                          </div>
                        </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Mitglied"/>}
                  onChange={(event, value) => {
                    setFilterField('member_id');
                    setMemberName(value?.name);
                    setFormState((prevState) => ({
                      ...prevState,
                      member_uid: parseInt(value?.memberID, 10),
                    }));
                  }}
              />
            </FormControl>

            <div style={{height: '20px'}}/>
            <FormControl fullWidth>
              <InputLabel id="activiy-select-label">Tätigkeit</InputLabel>
              <Select
                  labelId="activity-select-label"
                  id="activity-select"
                  value={formState.activity_uid}
                  label="Tätigkeit"
                  onChange={(event) => {
                    setFilterField('activity_uid');
                    setFormState((prevState) => ({
                      ...prevState,
                      activity_uid: parseInt(event?.target?.value.toString(), 10),
                    }));
                  }}>
                <MenuItem key={0} value={0}>
                  ---
                </MenuItem>
                {activities.map((activity) => (
                    <MenuItem key={activity.uid} value={activity.uid}>
                      {`${activity.name} / ${activity.categoryName}`}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{height: '20px'}}/>
            <FormControl fullWidth>
              <InputLabel id="activiy-select-label">Kultur</InputLabel>
              <Select
                  labelId="culture-select-label"
                  id="culture-select"
                  value={formState.culture_uid}
                  label="Kultur"
                  onChange={(event) => {
                    setFilterField('culture_uid');
                    setFormState((prevState) => ({
                      ...prevState,
                      culture_uid: parseInt(event?.target?.value.toString(), 10),
                    }));
                  }}>
                <MenuItem key={0} value={0}>
                  ---
                </MenuItem>
                {cultures.map((culture) => (
                    <MenuItem key={culture.uid} value={culture.uid}>
                      {culture.name}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{height: '20px'}}/>
            <FormControl fullWidth>
              <InputLabel id="place-select-label">Ort</InputLabel>
              <Select
                  labelId="place-select-label"
                  id="place-select"
                  value={formState.place_uid}
                  label="Ort"
                  onChange={(event) => {
                    setFilterField('place_uid');
                    setFormState((prevState) => ({
                      ...prevState,
                      place_uid: parseInt(event?.target?.value.toString(), 10),
                    }));
                  }}>
                <MenuItem key={0} value={0}>
                  ---
                </MenuItem>
                {places.map((place) => (
                    <MenuItem key={place.uid} value={place.uid}>
                      {place.name}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
              className="footer"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                justifySelf: 'flex-end',
                flex: 4,
              }}>
            <Button
                variant="outlined"
                style={{
                  width: '90%',
                  height: '50px',
                  marginLeft: '5%',
                  marginRight: '5%',
                  color: 'black',
                  borderColor: 'red',
                  justifySelf: 'flex-end',
                }}
                onClick={handleReset}>
              Filter löschen
            </Button>
            <Button
                style={{
                  width: '90%',
                  height: '50px',
                  marginLeft: '5%',
                  marginRight: '5%',
                  color: '#FFFFFF',
                  backgroundColor: '#1e6495',
                  justifySelf: 'flex-end',
                }}
                onClick={() => onClose(filterField, formState, sortValue)}>
              Fertig
            </Button>
          </div>
        </div>
      </SwipeableDrawer>
  );
};
export {SideFilterMenu};

