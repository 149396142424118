import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import EditHoursForm from '../components/ConsultingSessionList/ConsultingSessionEditForm';
import ConsultingSessionEnterForm from '../components/ConsultingSessionList/ConsultingSessionEnterForm';
import { useGlobalContext } from '../contexts/globalContext';
import ConsultingSessionService from '../services/ConsultingSessionService';
import SCREEN from '../utils/constants/SCREEN';

const styles = StyleSheet.create({
  btn: {
    width: 350,
    height: 50,
    alignSelf: 'center',
    color: '#FFFFFF',
    backgroundColor: '#1E9238',
  },
  backButton: {
    marginTop: 16,
    fontSize: 20,
    color: '#28465A',
    paddingLeft: 10,
  },
  headerMenu: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deleteContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    backgroundColor: '#f14c4c',
    borderRadius: 4,
  },
});

export default function ConsultingSessionCreate({ navigation, route }) {
  const { setActiveTab, setMemberName, setHistoryMemberID } = useGlobalContext();

  const handlePress = () => {
    if (route.params === 'home') {
      setActiveTab(0);
      setMemberName(null);
      setHistoryMemberID(null);
      navigation.navigate(SCREEN.HOME, {});
    } else {
      navigation.goBack();
      setHistoryMemberID(null);
      setMemberName(null);
    }
  };

  return (
    <View>
      <TouchableOpacity onPress={handlePress}>
        <Text style={styles.backButton}>
          <ArrowBackIosNewOutlinedIcon style={{ verticalAlign: 'bottom' }} />
          zurück
        </Text>
      </TouchableOpacity>
      <ConsultingSessionEnterForm navigation={navigation} route={route} />
    </View>
  );
}
